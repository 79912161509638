import AnalogClock from "../component/AnalogClock";
import { Speaker } from "../speaker/Speaker";
import "./TimeQuestion.css";

export function TimeQuestion({ id, hour, minute }) {
  function isCorrect(answer) {
    const result = getResult();
    return result == answer.replaceAll(" ", "");
  }

  function getResult() {
    return `${hour}:${String(minute).padStart(2, "0")}`;
  }

  function getQuestion() {
    // const question = `${num1} ${operation} ${num2} =`;
    return null;
  }

  function getHints() {
    return ["What time is it?"];
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return (
      <div className="timeQuestion">
        <AnalogClock hour={hour} minute={minute} />
      </div>
    );
  }

  async function read() {
    (await Speaker(true)).speak(getHints());
  }

  return {
    id,
    type: "TIME_QUESTION",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
  };
}
