import { getRandomInt, getRandomIntExcept } from "../utils";

export function getRandomSpellingWord() {
  const words = [
    ["Lot", "She has a lot of books."],
    ["After", "We can go out after dinner."],
    ["Again", "Let's try again."],
    ["Always", "She always smiles."],
    ["Animal", "The animal is friendly."],
    ["Another", "I need another pen."],
    ["Ask", "Can I ask you something?"],
    ["Away", "He walked away."],
    ["Back", "She came back home."],
    ["Because", "I stayed home because it was raining."],
    ["Been", "I have been there before."],
    ["Before", "Finish your work before you play."],
    ["Between", "The cat is between the chairs."],
    ["Blend", "Blend the ingredients well."],
    ["Boat", "The boat sailed smoothly."],
    ["Brother", "My brother is funny."],
    ["Buy", "I will buy some apples."],
    ["Call", "Please call me later."],
    ["Clean", "She likes to clean her room."],
    ["Clock", "The clock shows the time."],
    ["Cold", "It is very cold outside."],
    ["Deep", "The lake is very deep."],
    ["Deer", "A deer ran across the field."],
    ["Dish", "She washed the dish."],
    ["Dress", "She wore a red dress."],
    ["Drip", "Water began to drip from the tap."],
    ["Drive", "He can drive a car."],
    ["Each", "Each student has a book."],
    ["Eight", "There are eight apples in the basket."],
    ["Eleven", "The clock struck eleven."],
    ["Every", "Every child loves to play."],
    ["Family", "My family is very supportive."],
    ["Fast", "He runs very fast."],
    ["Fight", "They had a small fight."],
    ["First", "She was the first to arrive."],
    ["Found", "I found my lost keys."],
    ["Give", "Please give me the pen."],
    ["Goat", "The goat is eating grass."],
    ["Goes", "She goes to school daily."],
    ["Grow", "Plants grow with sunlight."],
    ["Happy", "She feels happy today."],
    ["Help", "Can you help me?"],
    ["Him", "I gave him a gift."],
    ["Home", "I am going home."],
    ["House", "Their house is big."],
    ["Just", "I just finished my work."],
    ["Kind", "She is very kind."],
    ["Kiss", "She gave him a kiss."],
    ["Line", "Draw a straight line."],
    ["Lion", "The lion roared loudly."],
    ["List", "Make a list of items."],
    ["Long", "The road is very long."],
    ["Look", "Look at the sky."],
    ["Loud", "The music is too loud."],
    ["Mess", "The room is a mess."],
    ["Might", "It might rain today."],
    ["Most", "She is the most talented."],
    ["New", "I bought a new phone."],
    ["Night", "The stars shine at night."],
    ["Nine", "There are nine books on the shelf."],
    ["Only", "She is the only child."],
    ["Or", "Do you want tea or coffee?"],
    ["Our", "This is our house."],
    ["Place", "This place is beautiful."],
    ["Plus", "Two plus two equals four."],
    ["Pool", "They swam in the pool."],
    ["Read", "I love to read books."],
    ["Rest", "Take some rest."],
    ["Right", "Turn right at the corner."],
    ["Say", "What did you say?"],
    ["Sea", "The sea is vast."],
    ["Second", "She came in second place."],
    ["Seven", "There are seven days in a week."],
    ["Shape", "The shape is round."],
    ["Sight", "The sight was breathtaking."],
    ["Sister", "My sister is kind."],
    ["Slide", "The children love to slide."],
    ["Slip", "Be careful not to slip."],
    ["Song", "She sang a beautiful song."],
    ["Soon", "I will see you soon."],
    ["Sound", "The sound is loud."],
    ["Still", "The water is still."],
    ["Stone", "He threw a stone."],
    ["Such", "Such a lovely day!"],
    ["Tell", "Tell me the truth."],
    ["Their", "Their house is big."],
    ["Them", "I saw them at the park."],
    ["Thing", "This thing is heavy."],
    ["Think", "I think it's a good idea."],
    ["Ton", "This weighs a ton."],
    ["Treat", "She gave me a treat."],
    ["Trick", "He played a trick on me."],
    ["Tune", "The tune is catchy."],
    ["Upon", "Once upon a time."],
    ["Us", "They invited us to the party."],
    ["Use", "Can I use your pen?"],
    ["Well", "She sings very well."],
    ["Went", "He went to the store."],
    ["Where", "Where are you going?"],
    ["Why", "Why are you late?"],
    ["Winter", "Winter is coming."],
    ["Wish", "I wish you happiness."],
    ["Write", "Please write your name."],
    ["Yard", "The yard is spacious."],
    ["Year", "This year has been great."],
    ["Air", "The air is fresh."],
    ["Also", "She is also coming."],
    ["There", "There is a cat on the roof."],
    ["Any", "Do you have any questions?"],
    ["Around", "Look around you."],
    ["Too", "It is too hot today."],
    ["Barn", "The barn is full of hay."],
    ["Bath", "She took a bath."],
    ["Twelve", "There are twelve months in a year."],
    ["Best", "She is my best friend."],
    ["Better", "This is a better option."],
    ["Very", "I am very happy."],
    ["Both", "Both options are good."],
    ["Bright", "The sun is bright."],
    ["Which", "Which one do you like?"],
    ["Cannot", "I cannot do it."],
    ["Child", "The child is playing."],
    ["Work", "I have to work today."],
    ["Could", "Could you help me?"],
    ["Count", "Can you count to ten?"],
    ["Yet", "I haven't finished yet."],
    ["Does", "Does she know?"],
    ["These", "These are my books."],
    ["Drop", "Don't drop the glass."],
    ["Drum", "He plays the drum."],
    ["Tray", "She carried the tray."],
    ["End", "This is the end."],
    ["Even", "Even he agreed."],
    ["Under", "The cat is under the table."],
    ["Fed", "I fed the dog."],
    ["Feed", "Please feed the fish."],
    ["Wash", "Wash your hands."],
    ["Friend", "She is my friend."],
    ["Gave", "I gave him a gift."],
    ["Who", "Who is that?"],
    ["Good", "This is good news."],
    ["Great", "You did a great job."],
    ["Would", "Would you like some tea?"],
    ["Here", "Come here."],
    ["High", "The mountain is high."],
    ["Your", "Your book is on the table."],
    ["It", "It is raining."],
    ["Jump", "He can jump high."],
    ["Seem", "You seem happy."],
    ["Large", "The room is large."],
    ["Light", "The light is bright."],
    ["Silly", "That was a silly joke."],
    ["Little", "The puppy is little."],
    ["Lock", "Lock the door."],
    ["Snack", "I had a snack."],
    ["Lunch", "Lunch is ready."],
    ["Made", "She made a cake."],
    ["Stamp", "Put a stamp on the letter."],
    ["Much", "Thank you very much."],
    ["Must", "You must try this."],
    ["Summer", "Summer is my favorite season."],
    ["Now", "Let's go now."],
    ["Off", "Turn off the light."],
    ["Send", "Please send me the file."],
    ["Out", "Let's go out."],
    ["Path", "Follow the path."],
    ["Sing", "She loves to sing."],
    ["Put", "Put it on the table."],
    ["Rabbit", "The rabbit is cute."],
    ["Speed", "He drove at high speed."],
    ["Rock", "He threw a rock."],
    ["Said", "She said hello."],
    ["State", "The state is large."],
    ["Take", "Take a seat."],
    ["Bring", "Bring your book."],
    ["Away", "She walked away."],
    ["Went", "He went home."],
    ["Road", "The road is long."],
    ["Trip", "We went on a trip."],
    ["House", "Their house is big."],
    ["Little", "The little bird sang."],
    ["Roof", "The roof is leaking."],
    ["Straw", "The straw is dry."],
    ["Rain", "It started to rain."],
    ["Cool", "The breeze is cool."],
    ["Planet", "Earth is a planet."],
    ["Sky", "The sky is blue."],
    ["Night", "The stars shine at night."],
    ["Money", "He saved his money."],
    ["Wing", "The bird has a broken wing."],
    ["Bird", "The bird is singing."],
    ["Beak", "The bird's beak"],
    ["Scrub", "He scrubbed the floor clean."],
    ["Fact", "It's a fact that cats purr."],
    ["Past", "In the past, he was a chef."],
    ["Held", "She held the baby gently."],
    ["Belt", "His belt was too tight."],
    ["Kept", "She kept the secret safe."],
    ["Strap", "The strap broke suddenly."],
    ["Slept", "He slept through the storm."],
    ["Both", "Both dogs barked loudly."],
    ["Wash", "She will wash the dishes."],
    ["Pants", "His pants were too short."],
    ["Know", "I know the answer now."],
    ["Sprint", "He sprinted to the finish."],
    ["Accept", "She couldn't accept the gift."],
    ["Adapt", "They had to adapt quickly."],
    ["Addition", "In addition, he likes tea."],
    ["Agree", "They agree on the plan."],
    ["Almonds", "Almonds are healthy snacks."],
    ["April", "April brings spring flowers."],
    ["Arrive", "They will arrive soon."],
    ["Attract", "Flowers attract bees."],
    ["August", "August is very hot."],
    ["Award", "He won an award."],
    ["Baboon", "The baboon climbed the tree."],
    ["Backpack", "Her backpack was heavy."],
    ["Bakery", "The bakery smells wonderful."],
    ["Bandage", "He put on a bandage."],
    ["Bare", "She walked on bare feet."],
    ["Bear", "The bear roared loudly."],
    ["Believe", "I believe in magic."],
    ["Beneath", "The cat hid beneath the bed."],
    ["Beyond", "The stars are beyond reach."],
    ["Blindfold", "He wore a blindfold."],
    ["Blurry", "The photo was blurry."],
    ["Boast", "He likes to boast."],
    ["Borrow", "Can I borrow a pen?"],
    ["Bought", "She bought a new dress."],
    ["Break", "Don't break the vase."],
    ["Butter", "She spread butter on toast."],
    ["Calendar", "Mark the date on the calendar."],
    ["Careful", "Be careful with the glass."],
    ["Castle", "The castle is old."],
    ["Cell", "The cell is small."],
    ["Cheetah", "A cheetah runs fast."],
    ["Chew", "Chew your food well."],
    ["Chicken", "The chicken clucked."],
    ["Chocolate", "Chocolate is sweet."],
    ["Citizen", "He is a good citizen."],
    ["Country", "They live in the country."],
    ["Cricket", "Cricket is a fun sport."],
    ["Curious", "The cat is curious."],
    ["Dangerous", "The road is dangerous."],
    ["Daughter", "She loves her daughter."],
    ["December", "December is cold."],
    ["Decide", "He will decide soon."],
    ["Dime", "A dime is small."],
    ["Discover", "They discover new things."],
    ["Division", "Division is a math operation."],
    ["Dollar", "He found a dollar."],
    ["Doze", "She likes to doze off."],
    ["Eagle", "The eagle soared high."],
    ["Earn", "He wants to earn money."],
    ["Easier", "This task is easier."],
    ["Edge", "She stood at the edge."],
    ["Environment", "Protect the environment."],
    ["Evening", "The evening is calm."],
    ["Everyone", "Everyone is here."],
    ["Everywhere", "They searched everywhere."],
    ["Example", "This is an example."],
    ["Excitement", "The excitement was high."],
    ["Exercise", "Exercise is important."],
    ["Extinct", "Dinosaurs are extinct."],
    ["Fabric", "The fabric is soft."],
    ["Fabulous", "She had a fabulous time."],
    ["Fact", "It's a fact."],
    ["Famous", "He is famous."],
    ["February", "February is short."],
    ["Flutter", "The butterfly fluttered."],
    ["Force", "Use gentle force."],
    ["Fraction", "A fraction of the cake."],
    ["Gathered", "They gathered together."],
    ["Giraffe", "The giraffe is tall."],
    ["Glide", "The bird can glide."],
    ["Governor", "The governor spoke."],
    ["Gravity", "Gravity pulls us down."],
    ["Greedy", "Don't be greedy."],
    ["Habitat", "Animals need a habitat."],
    ["Hamburgers", "Hamburgers are tasty."],
    ["Hear", "I can hear you."],
    ["Herd", "A herd of cows."],
    ["Here", "Come here."],
    ["Hint", "Give me a hint."],
    ["Holiday", "They went on holiday."],
    ["Injure", "Don't injure yourself."],
    ["Insect", "An insect buzzed."],
    ["Invent", "He likes to invent."],
    ["January", "January is cold."],
    ["Jelly", "Jelly is wobbly."],
    ["July", "July is hot."],
    ["Jumble", "The words were a jumble."],
    ["June", "June is warm."],
    ["Jungle", "The jungle is dense."],
    ["Kept", "She kept the book."],
    ["Kettle", "The kettle whistled."],
    ["Keyboard", "The keyboard is dusty."],
    ["Knee", "He hurt his knee."],
    ["Knock", "Knock on the door."],
    ["Label", "Label the jars."],
    ["Lend", "Can you lend me a book?"],
    ["Leopard", "The leopard is fast."],
    ["Library", "The library is quiet."],
    ["Limit", "Know your limit."],
    ["Lizard", "The lizard is small."],
    ["Lonely", "He felt lonely."],
    ["Macaroni", "Macaroni is delicious."],
    ["Mammal", "A whale is a mammal."],
    ["Mansion", "The mansion is huge."],
    ["March", "March is windy."],
    ["Matter", "It doesn't matter."],
    ["May", "May is springtime."],
    ["Mayor", "The mayor spoke."],
    ["Meat", "He likes meat."],
    ["Meet", "Let's meet tomorrow."],
    ["Money", "Money is important."],
    ["Motion", "The motion was smooth."],
    ["Multiplication", "Multiplication is easy."],
    ["Napkin", "Use a napkin."],
    ["Nearby", "The store is nearby."],
    ["Nearly", "It's nearly done."],
    ["Nervous", "He felt nervous."],
    ["Never", "Never give up."],
    ["Nickel", "A nickel is small."],
    ["Noisy", "The room was noisy."],
    ["Normal", "Everything is normal."],
    ["Notebook", "Write in your notebook."],
    ["November", "November is chilly."],
    ["October", "October is spooky."],
    ["Odd", "That's odd."],
    ["Odor", "The odor was strong."],
    ["Order", "Place your order."],
    ["Other", "The other side."],
    ["Owl", "The owl hooted."],
    ["Package", "The package arrived."],
    ["Pale", "She looked pale."],
    ["Panda", "The panda is cute."],
    ["Patterns", "The patterns are pretty."],
    ["Peanut", "He ate a peanut."],
    ["Penny", "A penny saved."],
    ["Place", "This is the place."],
    ["Plain", "The dress is plain."],
    ["Pleasant", "The weather is pleasant."],
    ["Porcupine", "The porcupine has quills."],
    ["Potato", "He likes potato chips."],
    ["Purpose", "What's the purpose?"],
    ["Quack", "The duck says quack."],
    ["Quarter", "A quarter is 25 cents."],
    ["Queen", "The queen waved."],
    ["Question", "Ask a question."],
    ["Quickly", "Finish quickly."],
    ["Quietly", "Speak quietly."],
    ["Quilt", "The quilt is warm."],
    ["Quiz", "We have a quiz today."],
    ["Raccoon", "The raccoon is sneaky."],
    ["Radio", "Turn on the radio."],
    ["Raise", "Raise your hand."],
    ["Reptile", "A snake is a reptile."],
    ["Right", "Turn right here."],
    ["Rounding", "Rounding numbers is easy."],
    ["Salmon", "Salmon is tasty."],
    ["Scholar", "She is a scholar."],
    ["Seal", "The seal clapped."],
    ["September", "September is cool."],
    ["Shiver", "He began to shiver."],
    ["Signal", "Give me a signal."],
    ["Smooth", "The surface is smooth."],
    ["Snuggle", "They snuggle on the couch."],
    ["Son", "He loves his son."],
    ["Soup", "The soup is hot."],
    ["Spaghetti", "Spaghetti is delicious."],
    ["Squash", "He likes to squash bugs."],
    ["Steady", "Keep a steady pace."],
    ["Subtraction", "Subtraction is simple."],
    ["Symmetry", "The design has symmetry."],
    ["Tangerine", "Tangerines are sweet."],
    ["Tasty", "The cake is tasty."],
    ["Telephone", "Answer the telephone."],
    ["Thank", "Thank you very much."],
    ["Their", "Their house is big."],
    ["There", "There it is."],
    ["Tiny", "The kitten is tiny."],
    ["Tour", "They went on a tour."],
    ["Trade", "They trade goods."],
    ["Unicorn", "A unicorn is magical."],
    ["Universe", "The universe is vast."],
    ["Unlock", "Unlock the door."],
    ["Vanilla", "Vanilla ice cream is yummy."],
    ["Veteran", "He is a war veteran."],
    ["Village", "The village is small."],
    ["Visit", "They visit often."],
    ["Voice", "Her voice is soft."],
    ["Waffles", "Waffles are for breakfast."],
    ["Warn", "He tried to warn them."],
    ["Weak", "He felt weak."],
    ["Wise", "The owl is wise."],
    ["Wonder", "I wonder what's next."],
    ["World", "The world is big."],
    ["Write", "Write your name."],
    ["Yawn", "She began to yawn."],
    ["Young", "The puppy is young."],
    ["Your", "Your book is here."],
    ["Zebra", "The zebra has stripes."],
  ];
  const index = getRandomInt(words.length - 1, 0);
  return words[index];
}

export function toNumber(input) {
  const numberMap = {
    O: 0,
    o: 0,
    a: 9,
    q: 9,
    b: 6,
    g: 9,
    s: 5,
    S: 5,
    z: 2,
    Z: 2,
    B: 8,
  };
  return numberMap[input] !== undefined ? numberMap[input] : input;
}

export function makeBalloonQuestion() {
  const n1 = getRandomInt(51, 10);
  const n2 = getRandomInt(n1 / 2, (-1 * n1) / 2);
  const n3 = -1 * getRandomInt(n1 + n2);

  function get2nd(num) {
    if (num === 0) return "";

    return num < 0
      ? `She gave away ${Math.abs(num)}.`
      : `She bought ${num} more.`;
  }
  return {
    context: `Nira bought ${n1} balloons. ${get2nd(n2)} ${
      n3 ? `${Math.abs(n3)} balloon popped.` : ""
    } How many balloons does Nira have left?`,
    result: n1 + n2 + n3,
  };
}

export function makeAppleQuestion() {
  const n1 = getRandomInt(51, 5);
  const n2 = -1 * getRandomInt(n1, 1);
  return {
    context: `Nick has ${n1} red apples and ${Math.abs(
      n2
    )} yellow apples. How many more red apples than yellow apples does he have?`,
    result: n1 + n2,
  };
}

export function makeBirdQuestion() {
  const n1 = getRandomInt(51, 5);
  const n2 = -1 * getRandomInt(n1, 1);
  return {
    context: `There are ${n1} birds on an apple tree. A squirrel chases ${Math.abs(
      n2
    )} away. How many birds are left on the tree?`,
    result: n1 + n2,
  };
}

export function makePencilQuestion() {
  const n1 = getRandomInt(20, 5);
  const n2 = getRandomInt(20, 2);
  const n3 = getRandomIntExcept(20, -1 * (n1 + n2), 0);
  function get3rd(num) {
    return num > 0
      ? `Moon gave him ${num} more.`
      : `He gave Moon ${Math.abs(num)}.`;
  }
  return {
    context: `Jamie has ${n1} pencils in his backpack and ${n2} pencils on the table.
              ${get3rd(n3)}
              How many pencils does he have altogether?`,
    result: n1 + n2 + n3,
  };
}

export function makeCandyQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = getRandomIntExcept(n1, -1 * n1, 0);
  function getNext(num) {
    return num > 0
      ? `Luna has ${num} more than Moon`
      : `Luna has ${abs(num)} fewer candies than Moon`;
  }
  return {
    context: `Moon has ${n1} candies. ${getNext(
      n2
    )}. How many candies does Luna have?`,
    result: n1 + n2,
  };
}

export function makeOrangeQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `A basket contains ${n1} oranges. If ${abs(
      n2
    )} oranges are taken out and ${n3} more are added, how many oranges are in the basket now?`,
    result: n1 + n2 + n3,
  };
}

export function makeBookQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `Lisa has ${n1} books. She lends ${abs(
      n2
    )} books to her friend and then buys ${n3} more. How many books does Lisa have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeMarbleQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `John has ${n1} marbles. He loses ${abs(
      n2
    )} marbles and then finds ${n3} more. How many marbles does John have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeMoneyQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = getRandomInt(20, 5);
  const n3 = -1 * getRandomInt(n1 + n2, 1);
  return {
    context: `Mike has ${n1} dollars. He earns ${n2} dollars from chores then spends ${abs(
      n3
    )} dollars on toys. How much money does Mike have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeStudentQuestion() {
  const n1 = getRandomInt(50, 20);
  const n2 = -1 * getRandomInt(n1, 1);
  const n3 = getRandomInt(20, 2);
  return {
    context: `A classroom has ${n1} students. ${abs(
      n2
    )} students leave for a field trip, and ${n3} new students join the class. How many students are in the classroom now?`,
    result: n1 + n2 + n3,
  };
}

export function makeFliesQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 1);
  const n3 = getRandomInt(20, 2);
  return {
    context: `There are ${n1} flies on a pizza. Jame swat ${abs(
      n2
    )} with his hands. But the pizza is so good that ${n3} more flies come. How many flies are on the pizza?`,
    result: n1 + n2 + n3,
  };
}

export function makeCowQuestion() {
  const n1 = getRandomInt(50, 5);
  const n2 = getRandomIntExcept(n1, -1 * n1, 0);
  const n3 = getRandomIntExcept(n1 + n2, -1 * (n1 + n2), 0);
  function buySell(num) {
    return num > 0 ? `buys ${abs(num)}` : `sells ${abs(num)}`;
  }
  return {
    context: `A farmer has ${n1} cows. He ${buySell(
      n2
    )} cows in the morning and then ${buySell(n3)} ${
      n2 * n3 > 0 ? "more" : ""
    } in the afternoon. How many cows does the farmer have?`,
    result: n1 + n2 + n3,
  };
}

export function makeBananaQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A monkey has ${n1} bananas. A parrot has ${moreLessThan(
      n2
    )} the monkey. How many bananas does the parrot have?`,
    result: n1 + n2,
  };
}

export function makePirateQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A pirate has ${n1} gold coins. A sailor has ${moreLessThan(
      n2
    )}  the pirate. How many gold coins does the sailor have?`,
    result: n1 + n2,
  };
}

export function makeDragonQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A dragon has ${n1} jewels. A knight has ${moreLessThan(
      n2
    )} the dragon. How many jewels does the knight have?`,
    result: n1 + n2,
  };
}

export function makeCatDogFishQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A cat has ${n1} fish. A dog has ${moreLessThan(
      n2
    )} the cat. How many fish does the dog have?`,
    result: n1 + n2,
  };
}

export function makeAntQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 2);
  return {
    context: `There are ${n1} ants at a picnic. If ${abs(
      n2
    )} ants leave but ${n3} more join, how many ants are at the picnic now?`,
    result: n1 + n2 + n3,
  };
}

export function makeFrogQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 2);
  return {
    context: `A frog has ${n1} flies. It eats ${abs(
      n2
    )} but catches ${n3} more. How many flies does the frog have now?`,
    result: n1 + n2 + n3,
  };
}

function moreLessThan(num) {
  if (num > 0) {
    return `${num} more than`;
  }

  const lessWord = getRandomInt(2) === 1 ? "less" : "fewer";
  return `${abs(num)} ${lessWord} than`;
}

export function abs(num) {
  return Math.abs(num);
}
