import handwriting from "../lib/hc";
import { useEffect } from "react";
import "./MathInput.css";

let anyDraftCanvas, textCanvas;
export default function DraftInput({ children, question, onCheckResult }) {
  useEffect(() => {
    if (anyDraftCanvas) {
      anyDraftCanvas.erase();
      anyDraftCanvas.destroy();
      anyDraftCanvas = null;
    }
    anyDraftCanvas = new handwriting.Canvas(
      document.getElementById("anyDraftCanvas"),
      2
    );

    anyDraftCanvas.setOptions({
      language: "en",
      numOfReturn: 1,
    });

    anyDraftCanvas.cxt.canvas.width = 440;
    anyDraftCanvas.cxt.canvas.height = 200;
    anyDraftCanvas.set_Undo_Redo(true, true);
  }, [question?.id]);

  useEffect(() => {
    if (textCanvas) {
      textCanvas.erase();
      textCanvas.destroy();
      textCanvas = null;
    }
    textCanvas = new handwriting.Canvas(
      document.getElementById("textCanvas"),
      2
    );

    textCanvas.setOptions({
      language: "en",
      numOfReturn: 1,
    });

    textCanvas.cxt.canvas.width = 200;
    textCanvas.cxt.canvas.height = 200;
    textCanvas.set_Undo_Redo(true, true);
  }, [question?.id]);

  function clearDraft() {
    anyDraftCanvas.erase();
  }

  function clearCanvas() {
    textCanvas.erase();
  }

  async function checkResult() {
    let answer = await textCanvas.recognize();

    const result = question.isCorrect(answer[0]);
    // console.log("answer", answer);
    // console.log(result);

    if (result) {
      textCanvas.destroy();
      textCanvas = null;
    }

    onCheckResult(result);
  }

  return (
    <div className="inputPanel">
      <div className="controllerContainer">
        {children}
        <button onClick={clearDraft} style={{ background: "orange" }}>
          <i className="fa fa-eraser"></i>
        </button>
        <span style={{ flexGrow: 1 }} />
        <button onClick={clearCanvas} style={{ background: "orange" }}>
          <i className="fa fa-eraser"></i>
        </button>
        <button
          onClick={checkResult}
          style={{ background: "#b71c1c", color: "white" }}
        >
          <i
            className="fa-solid fa-circle-half-stroke"
            style={{ transform: "rotate(-90deg)" }}
          ></i>
        </button>
      </div>
      <div className="canvasContainer">
        <canvas
          id="anyDraftCanvas"
          className="anyDraftCanvas"
          width="440"
          height="200"
          style={{ cursor: "crosshair" }}
        ></canvas>
        <canvas
          id="textCanvas"
          className="resultCanvas"
          width="200"
          height="200"
          style={{ cursor: "crosshair" }}
        ></canvas>
      </div>
    </div>
  );
}
