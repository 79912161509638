export function getRandomOperation(operations) {
  // const enabled = Object.keys(operations).reduce((init, cur) => {
  //   if (operations[cur]) {
  //     init.push(cur);
  //   }
  //   return init;
  // }, []);

  const ranIndex = getRandomInt(operations.length);
  return operations[ranIndex];
}

export function getRandomInt(max, min = 0) {
  const num = Math.floor(Math.random() * (max - min) + min);
  return num;
}

export function getRandomIntExcept(max, min = 0, except) {
  const num = getRandomInt(max, min);
  return num === except ? num + 1 : num;
}

export function getResult(op, num1, num2) {
  switch (op) {
    case "+":
      return num1 + num2;
    case "-":
      return num1 - num2;
    case "*":
      return num1 * num2;
    case "/":
      return num1 / num2;
  }
}

export function animate(elem, animation, duration) {
  elem.classList.add(animation);
  return new Promise((resolve) => {
    setTimeout(() => {
      elem.classList.remove(animation);
      resolve();
    }, duration);
  });
  // resolve, t, val));

  setTimeout(() => {}, duration);
  // return new Promise((resolve, reject) => {
  //   function handleAnimationEnd() {
  //     console.log("animation ended...");
  //     elem.classList.remove(animation);
  //     resolve(elem);
  //   }
  //   elem.addEventListener("animationend", handleAnimationEnd, { once: true });
  //   elem.classList.add(animation);
  // });
}

export function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}
