import "./Config.css";

export default function Config({
  maxNumber,
  operations,
  solved,
  total,
  onChange,
}) {
  const config = {
    operations,
    maxNumber,
    total,
  };

  function toggle({ currentTarget }) {
    const next = [...document.querySelectorAll(".operation:checked")].map(
      ($operation) => {
        return $operation.value;
      }
    );
    config.operations = next;
    onChange(config);
  }

  function onMaxNumberChange({ target }) {
    const { value } = target;
    config.maxNumber = value;
    onChange(config);
  }

  function onTotalChange({ target }) {
    const { value } = target;
    config.total = value;
    onChange(config);
  }

  return (
    <div id="configContainer">
      <div>
        Operations:
        <label className="toggle">
          <input
            type="checkbox"
            className="operation"
            id="plus"
            value={"+"}
            checked={operations.includes("+")}
            onChange={toggle}
          />
          +
        </label>
        <label className="toggle">
          <input
            type="checkbox"
            className="operation"
            id="minus"
            value={"-"}
            checked={operations.includes("-")}
            onChange={toggle}
          />
          -
        </label>
      </div>
      <div>
        Max:
        <input
          type="number"
          name=""
          id="maxNumber"
          value={maxNumber}
          onChange={onMaxNumberChange}
        />
      </div>
      <div className="overview">
        <span id="completed">{solved}</span>
        /
        <input
          type="number"
          id="total"
          value={total}
          onChange={onTotalChange}
        />
      </div>
    </div>
  );
}
