import { Speaker } from "../speaker/Speaker";
import { similarity } from "../utils";

export function TextQuestion({
  id = crypto.randomUUID(),
  poem,
  lineIndex,
  onCheckResult,
}) {
  const threshold = 0.6;

  function isCorrect(answer) {
    // return true;
    const line = poem[lineIndex];
    return similarity(line, answer) > threshold;
  }

  function getQuestion() {
    if (lineIndex === poem.length - 1) {
      return poem.slice(0, lineIndex + 1);
    }
    const lines = poem.slice(lineIndex, lineIndex + 1);
    return lines;
  }

  function renderQuestion(className) {
    const lines = poem.slice(0, lineIndex + 1);
    return (
      <div className={`questionTalk ${className}`}>
        {lines.map((line, id) => (
          <div key={`line-${id}`} className="textLine">
            {line}
          </div>
        ))}
      </div>
    );
  }

  async function read() {
    (await Speaker(true)).speak(getQuestion());
  }

  // function render() {
  //   return (
  //     <TextInput question={this} onCheckResult={onCheckResult}>
  //       {/* {getPlayButton()} */}
  //     </TextInput>
  //   );
  // }

  return {
    id,
    type: "TEXT_QUESTION",
    isCorrect,
    getQuestion,
    renderQuestion,
    read,
    // render,
  };
}
