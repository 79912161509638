import "./Shadow.css";

export function Shadow({ children, bottom = 6 }) {
  return (
    <div className="shadowContainer">
      {children}
      <div className="shadow" style={{ bottom: bottom }}></div>
    </div>
  );
}
