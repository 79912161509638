import { useEffect } from "react";
import { talk } from "../speaker/Speaker";
import "./PokemonTalk.css";

export default function PokemonTalk({ question, showAnswer }) {
  // useEffect(() => {
  //   setTimeout(async () => {
  //     await talk(question?.getHints?.()[0]);
  //     question?.getQuestion() && (await talk(question.getQuestion()));
  //   }, 1500);
  // }, [question?.id]);

  async function onClick(text) {
    await talk(text);
  }

  return (
    <div className="pokemonTalk">
      <div className="talkHint">
        {question?.renderHints?.("bubble", onClick)}
      </div>
      <div className="talkQuestion">
        {question?.renderQuestion?.("bubble", onClick)}
      </div>
      {showAnswer &&
        question.type === "SPELLING_QUESTION" &&
        question.renderAnswer()}
    </div>
  );
}
