// import TextInput from "../component/TextInput";
import { Speaker, talk } from "../speaker/Speaker";
import { similarity } from "../utils";

export function GrammarQuestion({
  id = crypto.randomUUID(),
  question: { q, h, a },
}) {
  const threshold = 0.8;

  function isCorrect(answer) {
    // return true;
    const correctAnswer = a;
    return similarity(correctAnswer, answer) > threshold;
  }

  function getQuestion() {
    return q;
  }

  function getHints() {
    return h;
  }

  function renderHints(className, onClick) {
    return h.map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return (
      <div
        className={`grammarQuestion ${className}`}
        onClick={() => onClick(getQuestion())}
      >
        {q}
      </div>
    );
  }

  async function read() {
    await talk(getHints()[0]?.replace(/_.*_/, ", blank,"));
    await talk(getQuestion());
  }

  // function render() {
  //   return (
  //     <TextInput question={this} onCheckResult={onCheckResult}>
  //       {/* {getPlayButton()} */}
  //     </TextInput>
  //   );
  // }

  return {
    id,
    type: "GRAMMAR_QUESTION",
    isCorrect,
    getQuestion,
    getHints,
    renderQuestion,
    renderHints,
    read,
    // render,
  };
}
