import MathInput from "../component/MathInput";
import { talk } from "../speaker/Speaker";

export function MathQuestion({ id, num1, num2, operation, onCheckResult }) {
  function isCorrect(answer) {
    const result = getResult();
    return result == answer;
  }

  function getResult() {
    switch (operation) {
      case "+":
        return num1 + num2;
      case "-":
        return num1 - num2;
    }
  }

  function getQuestion() {
    const question = `${num1} ${operation} ${num2} =`;
    return question;
  }

  function render() {
    return (
      <MathInput
        qid={id}
        isCorrect={isCorrect}
        question={getQuestion()}
        onCheckResult={onCheckResult}
      ></MathInput>
    );
  }

  async function read() {
    await talk(getQuestion());
  }

  return {
    id,
    isCorrect,
    getQuestion,
    render,
    read,
  };
}
