import { talk } from "../speaker/Speaker";
import { getRandomInt, getRandomOperation } from "../utils";
import { MathQuestion } from "./MathQuestion";

export function RandomMathQuestion({
  id = crypto.randomUUID(),
  operations = ["+", "-"],
  maxValue = 300,
  onCheckResult,
}) {
  const operation = getRandomOperation(operations);
  const num1 = getRandomInt(maxValue, 1);
  const maxNum2 = operation === "-" ? num1 : maxValue;
  const num2 = getRandomInt(maxNum2, maxNum2 * 0.2);

  const mathQuestion = MathQuestion({
    id,
    num1,
    num2,
    operation,
    onCheckResult,
  });

  function isCorrect(answer) {
    return mathQuestion.isCorrect(answer);
  }

  function getQuestion() {
    return mathQuestion.getQuestion();
  }

  async function read() {
    await talk(getQuestion().replace("-", "minus") + "?");
  }

  return {
    id,
    type: "MATH_QUESTION",
    isCorrect,
    getQuestion,
    read,
  };
}
