import { Speaker, talk } from "../speaker/Speaker";

export function SpellingQuestion({ id, word }) {
  let isRead = false;

  function isCorrect(answer) {
    const result = getResult();
    return result.toLowerCase() == answer.replaceAll(" ", "").toLowerCase();
  }

  function getResult() {
    return word[0];
  }

  function getQuestion() {
    return word[0];
  }

  function getHints() {
    return ["Can you spell this word?"];
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    // return <div className="spellingQuestion">Spelling</div>;
  }

  function renderAnswer() {
    return <div>{word[0]}</div>;
  }

  async function read() {
    if (!isRead) {
      await talk(getHints()[0]);
    }
    const [spelling, hint] = word;
    await talk(spelling);
    if (hint) {
      await talk("like: " + hint);
    }
    await talk(spelling);
    isRead = true;
  }

  return {
    id,
    type: "SPELLING_QUESTION",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
    renderAnswer,
  };
}
