import { getRandomInt } from "../utils";
import "./Fighter.css";
import { Shadow } from "./Shadow";

export default function Fighter({ status }) {
  const wins = [
    <img
      src="https://media.tenor.com/LtEswTq_UN8AAAAi/love-you-this-much-love-you.gif"
      style={{
        height: "70px",
      }}
    />,
    <img
      src="/assets/pikachu/win1.gif"
      style={{
        height: "44px",
        filter: "brightness(1.15)",
      }}
    />,
  ];

  function getImg(status) {
    switch (status) {
      case "WIN":
        const index = getRandomInt(2);
        return wins[index];
      case "LOOSE":
        return (
          <img
            src="https://i.pinimg.com/originals/80/e1/8d/80e18df0ed0ad872ac1a003d543d9613.gif"
            style={{
              height: "70px",
            }}
          />
        );
      default:
        return (
          <>
            <img src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-v/black-white/animated/back/25.gif" />
            {/* </Shadow> */}
          </>
        );
    }
  }

  return (
    <div className="fighter">
      <Shadow>{getImg(status)}</Shadow>
    </div>
  );
}
