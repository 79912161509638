import { useEffect, useRef, useState } from "react";
import { animate } from "../utils";
import "./PokemonContainer.css";
import { Shadow } from "./Shadow";

export default function PokemonContainer({
  children,
  isCatching,
  pokemon,
  question,
}) {
  const pokeballRef = useRef(null);
  const pokemonRef = useRef(null);

  useEffect(() => {
    (async () => {
      await animate(pokeballRef.current, "throwing", 600);
      await animate(pokeballRef.current, "catching", 1500);
    })();

    setTimeout(() => {
      animate(pokemonRef.current, "pokemonCatching", 1700);
    }, 600);
  }, [isCatching]);

  async function readQuestion() {
    await question?.read?.();
  }

  return (
    <div id="pokemonContainer">
      {children}
      <div style={{ width: "30%", position: "relative" }}>
        <div ref={pokemonRef} id="pokemon" onClick={() => readQuestion()}>
          <div ref={pokeballRef} className="pokeball"></div>
          <Shadow bottom={8}>
            <img style={{ width: "8em" }} src={pokemon?.json()?.front} />
          </Shadow>
        </div>
      </div>
    </div>
  );
}
