import { useEffect, useRef } from "react";
import "./AnalogClock.css";

export default function AnalogClock({ hour, minute, second = 0 }) {
  const hourRef = useRef();
  const minRef = useRef();
  const secRef = useRef();

  const d = new Date(0, 0, 0, hour, minute, second); //object of date()
  const hr = d.getHours();
  const min = d.getMinutes();
  // const sec = d.getSeconds();
  const hr_rotation = 30 * hr + min / 2; //converting current time
  const min_rotation = 6 * min;
  // const sec_rotation = 6 * sec;

  useEffect(() => {
    hourRef.current.style.transform = `rotate(${hr_rotation}deg)`;
    minRef.current.style.transform = `rotate(${min_rotation}deg)`;
    // secRef.current.style.transform = `rotate(${sec_rotation}deg)`;
  }, [hr_rotation, min_rotation]);

  return (
    <div id="clockContainer">
      <div id="minute" ref={minRef}></div>
      <div id="hour" ref={hourRef}></div>
      {/* <div id="second" ref={secRef}></div> */}
    </div>
  );
}
