import "./Pokebag.css";

export default function Pokebag({ pokebag, visible = true }) {
  return (
    <div id="pokebag" style={{ opacity: visible ? 1 : 0 }}>
      {pokebag.map((pokemon, i) => {
        return <img key={i} src={pokemon?.json()?.back} />;
      })}
    </div>
  );
}
