import { Shadow } from "./Shadow";
import "./Winning.css";

function Winning({ pokemons }) {
  return (
    <div className="slideHolder">
      <div className="pokemonsSlide">
        {pokemons.map((p) => {
          return (
            <span className="pokemonSlide">
              <Shadow>
                <img src={p.getFront()} />
              </Shadow>
            </span>
          );
        })}
      </div>
      <div className="pokemonsSlide">
        {pokemons.map((p) => {
          return (
            <span className="pokemonSlide">
              <Shadow>
                <img src={p.getFront()} />
              </Shadow>
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default Winning;
