export function Pokemon(id) {
  let pokemon;

  async function get() {
    // pokemon = await getPokemon(id);
    return pokemon;
  }

  function getCry() {
    return `https://raw.githubusercontent.com/PokeAPI/cries/main/cries/pokemon/latest/${id}.ogg`;
    // return pokemon?.cries?.latest;
  }

  function getFront() {
    return `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-v/black-white/animated/${id}.gif`;
    // return pokemon?.sprites?.versions['generation-v']['black-white']?.animated?.front_default;
  }

  function getBack() {
    return `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-v/black-white/animated/back/${id}.gif`;
    // return pokemon?.sprites?.versions['generation-v']['black-white']?.animated?.front_default;
  }

  function cry() {
    const cry = new Audio(getCry());
    cry.volume = 0.4;
    cry.play();
  }

  function json() {
    return {
      front: getFront(),
      back: getBack(),
      cry: getCry(),
    };
  }

  return {
    cry,
    get,
    getFront,
    json,
  };
}
