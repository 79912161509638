import handwriting from "../lib/hc";
import { useEffect } from "react";
import "./MathInput.css";

let mathDraftCanvas, mathResultCanvas;
export default function MathInput({ children, question, onCheckResult }) {
  useEffect(() => {
    if (mathDraftCanvas) {
      mathDraftCanvas.erase();
      mathDraftCanvas.destroy();
      mathDraftCanvas = null;
    }
    mathDraftCanvas = new handwriting.Canvas(
      document.getElementById("mathDraftCanvas"),
      2
    );

    mathDraftCanvas.setOptions({
      language: "en",
      numOfReturn: 1,
    });

    mathDraftCanvas.cxt.canvas.width = 440;
    mathDraftCanvas.cxt.canvas.height = 200;
    mathDraftCanvas.set_Undo_Redo(true, true);
  }, [question?.id]);

  useEffect(() => {
    if (mathResultCanvas) {
      mathResultCanvas.erase();
      mathResultCanvas.destroy();
      mathResultCanvas = null;
    }
    mathResultCanvas = new handwriting.Canvas(
      document.getElementById("mathResultCanvas"),
      2
    );

    mathResultCanvas.setOptions({
      language: "en",
      numOfReturn: 1,
    });

    mathResultCanvas.cxt.canvas.width = 200;
    mathResultCanvas.cxt.canvas.height = 200;
    mathResultCanvas.set_Undo_Redo(true, true);
  }, [question?.id]);

  function clearDraft() {
    mathDraftCanvas.erase();
  }

  function clearResult() {
    mathResultCanvas.erase();
  }

  function resetCanvas() {
    clearDraft();
    clearResult();
  }

  async function checkResult() {
    let answer = await mathResultCanvas.recognize();
    // console.log(answer);
    const numberMap = {
      O: 0,
      o: 0,
      a: 9,
      q: 9,
      b: 6,
      g: 9,
      s: 5,
      S: 5,
      z: 2,
      Z: 2,
      B: 8,
    };

    if (numberMap[answer]) {
      answer = numberMap[answer];
    }

    const result = question.isCorrect(answer);
    // console.log("answer", answer);
    // console.log(result);

    if (result) {
      mathDraftCanvas.destroy();
      mathDraftCanvas = null;

      mathResultCanvas.destroy();
      mathResultCanvas = null;
    }

    onCheckResult(result);
  }

  return (
    <div className="inputPanel">
      <div className="controllerContainer">
        {children}
        <button onClick={clearDraft} style={{ background: "orange" }}>
          <i className="fa fa-eraser"></i>
        </button>
        <span style={{ flexGrow: 1 }} />
        <button onClick={clearResult} style={{ background: "orange" }}>
          <i className="fa fa-eraser"></i>
        </button>
        <button
          onClick={checkResult}
          style={{ background: "#b71c1c", color: "white" }}
        >
          <i
            className="fa-solid fa-circle-half-stroke"
            style={{ transform: "rotate(-90deg)" }}
          ></i>
        </button>
      </div>
      <div className="canvasContainer">
        <div className="questionContainer">
          <div className="question">
            <span>{question?.getQuestion()}</span>
            <span id="mathResult"></span>
          </div>
          <canvas
            id="mathDraftCanvas"
            className="draftCanvas"
            width="440"
            height="200"
          ></canvas>
        </div>

        <canvas
          id="mathResultCanvas"
          className="resultCanvas"
          width="200"
          height="200"
          style={{ cursor: "crosshair" }}
        ></canvas>
      </div>
    </div>
  );
}
